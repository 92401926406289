$yellow: #E1FE1A;

@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../node_modules/bootstrap-block-grid/src/bootstrap3-block-grid";
@import "../node_modules/slick-carousel/slick/slick.scss";
@import "../node_modules/slick-carousel/slick/slick-theme";

body {
  font-family: 'proxima-nova', sans-serif;
  background: #FFF;
  color: #000;
  font-size: 18px;
}

.img-responsive {
  @media (min-width: 1921px) {
    width: 100%;
  }
}

.header-video {
  .video-constrain {
    max-width: 1440px;
    margin: 0 auto;
    padding: 10px;
    @media (min-width: $screen-sm-min) {
      padding: 40px;
    }
  }
  .embed-responsive {
    border: solid 2px $yellow;
  }
}

.reel-overlay {
  margin: auto;
}

.gallery-title {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .3em;
  span {
    color: #000;
  }
}

.img-gallery-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  @media (min-width: $screen-sm-min) {
    padding: 40px;
  }
  .img-gallery {
    border: solid 2px $yellow;
  }
  .prev, .next {
    position: absolute;
    display: block;
    line-height: 1;
    font-size: 40px;
    cursor: pointer;
    background: transparent;
    color: #000;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
  }
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }
}

.footer {
  background: $yellow;
  padding: 40px 0;
  text-align: center;
  border-radius: 0;
  h1 {
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: -0.02em;
    margin: 0;
    color: #222;
    @media (min-width: $screen-lg-min) {
      font-size: 70px;
      letter-spacing: 0.1em;
    }
    @media (min-width: $screen-sm-min) {
      font-size: 40px;
      letter-spacing: 0.1em;
    }
  }
}

.a360 {
  position: relative;
  max-width: 275px;
  max-height: 600px;
  margin: auto;
  @media (min-width: $screen-md-min) {
    margin: 0;
  }
  .a360--icon {
    position: absolute;
    top: 0;
  }
}

.font-italic {
  font-style: italic;
}

.font-medium {
  font-weight: 500;
}

.font-light {
  font-weight: 300;
}

.font-xbold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.border {
  border: solid 3px #000;
}

.border-hivis {
  border-top: solid 16px $yellow;
}

.row.no-gutters {
  padding: 0 !important;
  margin: 0 !important;
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.style-tag {
  position: absolute;
  text-transform: uppercase;
  padding: 5px 80px;
  background: $yellow;
  right: 13%;
  bottom: -55px;
  font-size: 32px;
  font-style: italic;
  z-index: 2;
}

.section-title {
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: -.02em;
  color: #000;
  @media (min-width: $screen-sm-min) {
    font-size: 50px;
  }
  small {
    display: block;
    font-size: 18px;
    letter-spacing: .6em;
    color: $yellow;
    @media (min-width: $screen-sm-min) {
      font-size: 21px;
    }
  }
}

.features {
  font-size: 25px;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-top: 40px;
  font-weight: 800;
  margin-left: 10px;
}

hr {
  border-color: #636363;
  border-width: 3px;
}

.text-center {
  img {
    margin: 0 auto;
  }
}

.has-row-title {
  position: relative;
}

.row-title {
  @media (min-width: $screen-md-min) {
    position: absolute;
    top: 112px;
    left: -20px;
    transform: rotate(-90deg);
    transform-origin: top left 0;
    float: left;
    margin: 0;
  }
  margin: 0 0 10px 15px;
  text-transform: uppercase;
  font-size: 21px;
  letter-spacing: .1em;
}

.feature-name {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 2px;
}

.feature-description {
  font-size: 16px;
  letter-spacing: .025em;
  font-weight: 400;
  line-height: 1.26;
}

.colorways, .styles {
  @media (min-width: $screen-md-min) {
    margin: 0 40px 0 90px;
  }
}

.colorway {
  margin-top: 20px;
  font-size: 15px;
  letter-spacing: .05em;
  font-weight: 600;
  text-align: center;
}

.highlight {
  text-align: left;
  @media (min-width: $screen-md-min) {
    text-align: right;
  }
  .img-boa {
    max-width: 50%;
    @media (min-width: $screen-md-min) {
      max-width: 100%;
    }
  }
  .right-column {
    margin-top: 20px;
    z-index: 2;
    @media (min-width: $screen-md-min) {
      margin-top: 100px
    }
    @media (min-width: $screen-lg-min) {
      margin-top: 150px
    }
  }
  .description {
    margin-top: 10px;
    text-align: left;
    font-weight: 500;
    @media (min-width: $screen-md-min) {
      text-align: right;
      margin-top: 50px
    }
    @media (min-width: $screen-lg-min) {
      margin-top: 100px
    }
  }
}

.styles {
  .style-title {
    font-size: 25px;
    letter-spacing: -.02em;
    text-transform: uppercase;
    color: #000;
    margin-top: 40px;
    font-weight: 800;
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      bottom: 10px;
      width: 100%;
      margin-left: 10px;
      height: 3px;
      position: absolute;
      background: $yellow;
    }
  }
}

.product-name {
  color: $yellow;
  font-weight: 600;
  font-size: 33px;
  letter-spacing: .05em;
}

.bullets {
  font-size: 19px;
  letter-spacing: .025em;
  margin-top: 30px;
  font-weight: 600;
  margin-left: 20px;
  p span {
    margin-left: -20px;
  }
}

.image-fit {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  background: #FFF;
  img {
    position: absolute;
    max-width: 95%;
    max-height: 95%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-t-100 {
  margin-top: 100px;
}

.m-t-150 {
  margin-top: 150px;
}

.m-t-n100 {
  @media (min-width: $screen-lg-min) {
    margin-top: -100px;
  }
}

.m-t-l100 {
  @media (min-width: $screen-lg-min) {
    margin-top: 100px;
  }
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-l-n150 {
  margin-left: -150px;
}

.m-l-n200 {
  margin-left: -200px;
}

.m-l-theme {
  margin-left: 5.5vw;
}

.p-r-100 {
  padding-right: 100px;
}

.p-r-175 {
  padding-right: 175px;
}

.relative {
  position: relative;
}

.margin-auto {
  margin: auto;
}